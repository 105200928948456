import { useCallback, useState } from "react";

export const useModalLoader = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [doc, setDoc] = useState(null);

  const fetchAdmin = async (data) => {
    setDoc(data);
    setLoading(false);
  };

  const modalHandler = async (bool) => {
    setOpen(bool);
  };

  const clearDoc = () => {
    setDoc(null);
  };

  const loadingHandler = useCallback((bool) => {
    setLoading(bool);
  }, []);

  return {
    open,
    modalHandler,
    loading,
    loadingHandler,
    doc,
    clearDoc,
    fetchAdmin,
  };
};
