import axios from "axios";

export const API_URL = "https://pdf-generator-api-5103b9a3e23b.herokuapp.com/";

export const axiosAuth = axios.create({
  baseURL: API_URL,
  //baseURL: "http://localhost:8080",
});

axiosAuth.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");

  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});
