import { Suspense, lazy, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import { axiosAuth as axios, API_URL } from "./util/axios-instance";

const Login = lazy(() => import("./components/Login"));
const Dashboard = lazy(() => import("./components/Dashboard"));
const Inactive = lazy(() => import("./components/Inactive"));
const PublicLayout = lazy(() => import("./routing/PublicLayout"));
const PrivateLayout = lazy(() => import("./routing/PrivateLayout"));
const Profile = lazy(() => import("./components/Profile"));
const Error = lazy(() => import("./components/Error"));
const Header = lazy(() => import("./components/Header"));
const Footer = lazy(() => import("./components/Footer"));

function App() {
  useEffect(() => {
    const fetchApi = async () => {
      const res = await axios(API_URL);

      if (res.data !== "Hello World!") console.log("API ERROR");
    };
    fetchApi();
  }, []);

  return (
    <Suspense fallback={<CircularProgress />}>
      <div className="App">
        <Header />
        <Routes>
          <Route element={<PublicLayout />}>
            <Route path="/" element={<Login />} />
          </Route>

          <Route element={<PrivateLayout />}>
            <Route path="/inactive" element={<Inactive />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/user" element={<Profile />} />
          </Route>
          <Route path="*" element={<Error />} />
        </Routes>
        <Footer />
      </div>
    </Suspense>
  );
}

export default App;
