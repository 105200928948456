import { createContext, useMemo } from "react";
import { useData } from "./data-hook";
import { useModalLoader } from "./modal-hook";
import { useAuth } from "./auth-hook";
import { useClick } from "./clicked-hook";

export const AppContext = createContext({
  user: null,
  login: () => {},
  logout: () => {},
  forgotPassword: () => {},
  changePassword: () => {},
  registerAccount: () => {},
  response: { message: "", status: 0 },
  addResponse: () => {},
  clearResponse: () => {},
  rulebookDate: "",
  rulebookRef: "",
  dateRequest: "",
  year: "",
  firstName: "",
  lastName: "",
  job: "",
  duration: "",
  startDate: "",
  endDate: "",
  returnDate: "",
  gender: "",
  allow: false,
  clicked: false,
  clickHandler: () => {},
  setters: {
    dateRequestSetter: () => {},
    yearSetter: () => {},
    firstNameSetter: () => {},
    lastNameSetter: () => {},
    durationSetter: () => {},
    jobSetter: () => {},
    startDateSetter: () => {},
    endDateSetter: () => {},
    returnDateSetter: () => {},
    genderSetter: () => {},
  },
  clearState: () => {},
  fetchRulebookInfo: () => {},
  open: false,
  modalHandler: () => {},
  loading: true,
  loadingHandler: () => {},
  doc: {},
  clearDoc: () => {},
  fetchAdmin: () => {},
});

export const AppProvider = ({ children }) => {
  const {
    allow,
    rulebookDate,
    rulebookRef,
    dateRequest,
    year,
    firstName,
    lastName,
    job,
    duration,
    startDate,
    endDate,
    returnDate,
    gender,
    setters,
    clearState,
    fetchRulebookInfo,
  } = useData();
  const {
    open,
    modalHandler,
    loading,
    loadingHandler,
    doc,
    clearDoc,
    fetchAdmin,
  } = useModalLoader();
  const {
    user,
    login,
    logout,
    registerAccount,
    forgotPassword,
    changePassword,
    response,
    addResponse,
    clearResponse,
  } = useAuth();
  const { clicked, clickHandler } = useClick();

  const values = useMemo(
    () => ({
      login,
      logout,
      registerAccount,
      forgotPassword,
      changePassword,
      user,
      clicked,
      clickHandler,
      response,
      addResponse,
      clearResponse,
      allow,
      rulebookDate,
      rulebookRef,
      dateRequest,
      year,
      firstName,
      lastName,
      job,
      duration,
      startDate,
      endDate,
      returnDate,
      gender,
      setters: {
        dateRequestSetter: setters.dateRequestSetter,
        yearSetter: setters.yearSetter,
        firstNameSetter: setters.firstNameSetter,
        lastNameSetter: setters.lastNameSetter,
        durationSetter: setters.durationSetter,
        jobSetter: setters.jobSetter,
        startDateSetter: setters.startDateSetter,
        endDateSetter: setters.endDateSetter,
        returnDateSetter: setters.returnDateSetter,
        genderSetter: setters.genderSetter,
      },
      clearState,
      fetchRulebookInfo,
      open,
      modalHandler,
      loading,
      loadingHandler,
      doc,
      clearDoc,
      fetchAdmin,
    }),
    [
      login,
      logout,
      registerAccount,
      forgotPassword,
      changePassword,
      user,
      clicked,
      clickHandler,
      response,
      addResponse,
      clearResponse,
      allow,
      clearState,
      dateRequest,
      duration,
      endDate,
      firstName,
      lastName,
      gender,
      job,
      modalHandler,
      open,
      returnDate,
      rulebookDate,
      rulebookRef,
      startDate,
      year,
      setters.dateRequestSetter,
      setters.durationSetter,
      setters.endDateSetter,
      setters.firstNameSetter,
      setters.genderSetter,
      setters.jobSetter,
      setters.lastNameSetter,
      setters.returnDateSetter,
      setters.startDateSetter,
      setters.yearSetter,
      loading,
      loadingHandler,
      doc,
      clearDoc,
      fetchAdmin,
      fetchRulebookInfo,
    ]
  );

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
};
